import { React } from 'react';

import homecare from './assets/home_healthcare.jpg';
import telephonic2 from './assets/telephonic1.jpg';
import { SiHomeadvisor } from 'react-icons/si';
import { BsCheckSquareFill } from 'react-icons/bs'
import {FaUserDoctor} from 'react-icons/fa6'
const Features = () => {
    return (
        <>

            <div id='features'>
                <div className='mb-5 text-center'>
                    <div className='text-2xl '> We bring hospital-level care within the comfort of your home</div>
                    When you’re dealing with an urgent medical need, we’ll bring exceptional healthcare to you
                </div>
                <div className='lg:flex w-[84%]'>
                    <div className='lg:w-1/2'>
                        <img src={homecare} alt="homecare" width={'70%'} />
                    </div>

                    <div className='lg:w-1/2'>
                        <div className='flex mb-2 text-xl'> <SiHomeadvisor className='text-2xl text-pink-500 me-3 ' /> Home Healthcare Services</div>

                        <ul className='home_care ms-1'>
                            <li className='mb-1'>
                                 <BsCheckSquareFill className='text-violet-300 me-4' /> Wound Care </li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' />Chronic Care</li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' />Trauma Care</li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' />Urinary Catheter Care</li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' />Injection and IV Management</li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' />Post Operative Care</li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' />Medical Counselling</li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300 ' />Physiotherapy</li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' />Elder Care and Caregiving</li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' />Hospice and Palliative care</li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' />Mother and Baby care</li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' />Provide Extended Service to your Patients</li>
                        </ul>
                    </div>
                </div>
                <div className='lg:flex w-[84%] '>
                   

                    <div className='mt-20 lg:w-1/2'>
                        <div className='flex mb-2 text-xl'> <FaUserDoctor className='text-2xl text-pink-500 me-3 ' /> TeleHealthcare</div>

                        <ul className='home_care ms-1'>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' /> Doctor Consultation </li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' />Counselling</li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' />Physiotherapy</li>
                            <li className='mb-1'> <BsCheckSquareFill className='me-4 text-violet-300' />Occupational Therapy & Rehabilitation</li>
                           
                        </ul>
                    </div>
                    <div className='lg:w-1/2'>
                        <img src={telephonic2} alt="homecare" width={'80%'} />
                    </div>
                </div>
            
            </div>
        </>
    )
}

export default Features;