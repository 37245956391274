import { React } from 'react';
import { BsCheckSquareFill } from 'react-icons/bs'
const Privacy = () => {
    return (
        <>
            {/* <!-- FAQ --> */}
            <div class=" px-4 py-10 sm:px-6 lg:px-4 lg:py-14">
                {/* <!-- Title --> */}

                {/* <!-- End Title --> */}

                <div class="max-w-5xl mx-auto divide-y divide-gray-200 dark:divide-gray-700">
                    <div class="py-8 first:pt-0 last:pb-0">
                        <div class="flex gap-x-5">


                            <div>
                                <h3 class="md:text-lg font-semibold text-blue-500 ">
                                    Purpose
                                </h3>
                                <p class="mt-1 text-gray-500">
                                    ATHRU Technologies Private Limited (hereinafter mentioned as “Medverse”) ("we," "us," or "our") is committed to protecting the privacy and security of your personal information while you access our website and mobile application. This Privacy Policy describes how we collect, use, and disclose personal information through our website https://www.medverse.care/ (the "Site"). By accessing or using the Site, you consent to the terms and practices described in this Privacy Policy.  'You' shall mean You, the User of the Website/ Application and ‘Yourself’ interpreted accordingly. 'We' / 'Us' means Startup India and 'Our' interpreted accordingly. 'Users' means the Users of the Website/ application collectively and/or individually as the context allows.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="py-8 first:pt-0 last:pb-0">
                        <div class="flex gap-x-5">


                            <div>
                                <h3 class="md:text-lg font-semibold text-blue-500 ">
                                    Information We Collect
                                </h3>
                                <p class="mt-1 text-gray-500">
                                    <div className='mb-2'>Medverse website or applications and any other related links, do not automatically capture any specific information from you, that allows us to identify you individually.
                                        We may collect and process the following types of personal information:</div>
                                    <span className='font-bold text-violet-700'>Personal Information :</span>  such as your name, address, email address, phone number, and other contact details.
                                    <span className='inline-block mt-2 font-bold text-violet-700'>Sensitive Personal Data or Information (SPDI):</span> if required for the services provided, we may collect SPDI such as financial information, passwords, or any other information deemed sensitive under applicable laws.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="py-8 first:pt-0 last:pb-0">
                        <div class="flex gap-x-5">


                            <div>
                                <h3 class="md:text-lg font-semibold text-blue-500">
                                    How We Collect Information
                                </h3>
                                <p class="mt-1 text-gray-500">
                                    We collect information directly from you when you:

                                    <ul className='home_care ms-1'>
                                        <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' /> Register on our Site.</li>
                                        <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' /> Fill out a form.</li>
                                        <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' /> Subscribe to our newsletter.</li>
                                        <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' /> Use our services.</li>

                                    </ul>



                                    We may also collect information automatically when you visit our Site, including through the use of cookies.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="py-8 first:pt-0 last:pb-0">
                        <div class="flex gap-x-5">


                            <div>
                                <h3 class="md:text-lg fonmd:text-lg font-semibold text-blue-500">
                                    How We Use Your Information
                                </h3>
                                <p class="mt-1 text-gray-500">
                                    <div className='mb-2'> Medverse website or applications requests you to provide personal information, you will be informed for the particular purposes for which the information is being gathered and adequate security measures will be taken to protect your personal information.
                                    </div>

                                    <div className='mb-2'>
                                        Medverse website or applications may collect your personal information for various regulatory and business purposes. These include, but are not limited to:
                                    </div>

                                    <div>

                                        <ul className='home_care ms-1'>
                                            <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' />  To Verify your identity.</li>

                                            <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' />  For improvement of our product and services.</li>
                                            <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' />  To complete transactions effectively and bill for services or products availed. </li>
                                            <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' />  To carry out our obligations arising from any agreements entered into between you and Medverse. </li>
                                            <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' />  To respond to your request for service or assistance  </li>
                                            <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' />  To process your job applications.  </li>
                                            <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' />  For data analytics.   </li>
                                            <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' />  To promote and market our product or services which we consider may be of interest to you and may benefit you;    </li>
                                            <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' />  To ensure adherence to legal and regulatory requirements for prevention and detection of frauds and crimes.  </li>


                                        </ul>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="py-8 first:pt-0 last:pb-0">
                        <div class="flex gap-x-5">
                           

                            <div>
                                <h3 class="md:text-lg fonmd:text-lg font-semibold text-blue-500">
                                How We Share Your Information
                                </h3>
                                <p class="mt-1 text-gray-500">
                                We may share your information with trusted third parties, such as service providers, who assist us in operating the Site or conducting our business. We will not sell, trade, or rent your personal information to third parties without your consent.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="py-8 first:pt-0 last:pb-0">
                        <div class="flex gap-x-5">
                           

                            <div>
                                <h3 class="md:text-lg fonmd:text-lg font-semibold text-blue-500">
                                Security
                                </h3>
                                <p class="mt-1 text-gray-500">
                                We implement reasonable security practices and procedures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="py-8 first:pt-0 last:pb-0">
                        <div class="flex gap-x-5">
                         

                            <div>
                                <h3 class="md:text-lg fonmd:text-lg font-semibold text-blue-500">
                                Your Rights
                                </h3>
                                <p class="mt-1 text-gray-500">
                                You have the right to:

                                <ul className='home_care ms-1'>
                                            <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' />  Review, update, or delete your personal information.</li>
                                            <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' /> Withdraw your consent for processing your information.</li>
                                            <li className='mb-1'>  <BsCheckSquareFill className='inline text-violet-300 me-4' /> Object to the processing of your information.</li>
                                            </ul>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="py-8 first:pt-0 last:pb-0">
                        <div class="flex gap-x-5">
                            <div>
                                <h3 class="md:text-lg fonmd:text-lg font-semibold text-blue-500">
                                Changes to This Privacy Policy
                                </h3>
                                <p class="mt-1 text-gray-500">
                                We reserve the right to update or change this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy on the Site.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="py-8 first:pt-0 last:pb-0">
                        <div class="flex gap-x-5">
                            <div>
                                <h3 class="md:text-lg fonmd:text-lg font-semibold text-blue-500">
                                Contact Us
                                </h3>
                                <p class="mt-1 text-gray-500">
                                <div>If you have any questions about this Privacy Policy, please contact us at 
                                <a href="mailto: customersupport@medverse.care" className='text-blue-600 underline' > customersupport@medverse.care </a>
                                </div>

                                Medverse reserves the right to amend or modify this Privacy Policy at any time, as and when the need arises. We request you to visit our website www.medverse.care periodically for contemporary information and changes.
                                </p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            {/* <!-- End FAQ --> */}
        </>
    )
}

export default Privacy;