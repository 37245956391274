import { React } from 'react';
import partership from './assets/Partnership.png'
import { BsCheckSquareFill } from 'react-icons/bs';
const Partner = () => {
  return (
    <>

      <section className="bg-white p-10 lg:p-[120px] overflow-hidden relative ">
        <div className="container">
          <div className="flex flex-wrap -mx-4 lg:justify-between">
            <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
              <div className="mb-12 max-w-[570px] lg:mb-0">
                <h2 className="mb-6 text-[25px] font-bold uppercase text-dark sm:text-[40px] lg:text-[36px] xl:text-[40px]">
                  Become Our Partner
                </h2>
                <p className="text-base leading-relaxed mb-9 text-body-color">
                  Collaborating with Medverse grants you access to a fully integrated in-home healthcare system, available for deployment whenever and wherever you need it.


                </p>
                <div className="relative flex w-full p-5 mb-8 bg-white rounded-sm shadow-lg">
                 
                  <div className='w-full'>
                    <div className='mb-4 text-xl text-pink-700'>Healthcare Providers</div>
                    <div>
                      Medverse network of Healthcare professionals helps healthcare providers to extend care into the home which leads to patient satisfaction & better outcome.
                    </div>
                    <div className='mt-3 mb-2'>
                      Home healthcare at your fingertips, ready to serve whenever you want it.
                    </div>
                    <div>
                      <ul>
                        <li className='flex'>
                          <BsCheckSquareFill className='mt-1 text-violet-300 me-4' />
                           Extended care at home </li>
                        <li className='flex'>
                          <BsCheckSquareFill className='mt-1 text-violet-300 me-4' />
                          Enhanced Care Continuity
                        </li>
                        <li className='flex'>
                          <BsCheckSquareFill className='mt-1 text-violet-300 me-4' /> Better Patient Experience </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="relative flex w-full p-5 mb-8 bg-white rounded-sm shadow-lg">
                 
                  <div className='w-full'>
                    <div className='mb-4 text-xl text-pink-700'>Doctors</div>
                    <div>
                    Medvrse technology will support your telehealth practice & helps in setting up a digital clinic.
                    </div>
                    <div className='mt-3 mb-2'>
                    In-person, at your terms & at your patient's convenience
                    </div>
                    <div>
                      <ul>
                        <li className='flex'>
                          <BsCheckSquareFill className='mt-1 text-violet-300 me-4' />
                          In-Home care </li>
                        <li className='flex'>
                          <BsCheckSquareFill className='mt-1 text-violet-300 me-4' />
                          Get In-person vital data remotely
                        </li>
                        <li className='flex'>
                          <BsCheckSquareFill className='mt-1 text-violet-300 me-4' />Enhance your practice</li>
                      </ul>
                    </div>
                  </div>
                </div>
               


              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <img src={partership} alt="partership" className='m-auto' />
              <div className="relative flex w-full p-5 mb-8 bg-white rounded-sm shadow-lg">
                 
                 <div className='w-full'>
                   <div className='mb-4 text-xl text-pink-700'>Nurses & Allied Healthcare Professionals
</div>
                   
                   <div className='mt-3 mb-2'>
                   Medverse needs your support to make medical services available across the country.
                   </div>
                   <div>
                     <ul>
                       <li className='flex'>
                         <BsCheckSquareFill className='mt-1 text-violet-300 me-4 text-[19px] lg:text-[16px]'  />
                         Serve more, earn more, on your terms </li>
                       <li className='flex'>
                         <BsCheckSquareFill className='mt-1 text-violet-300 me-4' />
                         Make the most of your skills
                       </li>
                       <li className='flex'>
                         <BsCheckSquareFill className='mt-1 text-violet-300 me-4' /> 
                         Make more earnings </li>
                       <li className='flex'>
                         <BsCheckSquareFill className='mt-1 text-violet-300 me-4' /> 
                         Set your work schedule </li>
                     </ul>
                   </div>
                 </div>
               </div>
            </div>
          </div>
        </div>
      </section>
     
    </>
  )
}

export default Partner;