// import { React} from 'react';
import { React, useState } from 'react';
import Features from './Feature';
import Doctor from './assets/doctor_png.png';
import AboutImages1 from './assets/download.png';
import Contact from './Contact';
import Brandlogo from './assets/main_logo.png';
import Works from './works';
import Partner from './partner';
import { BsTwitter, BsYoutube, BsLinkedin } from 'react-icons/bs';
import { GoMoveToTop } from 'react-icons/go';
import FeatureBox from './FeatureBox';
const Landing=()=>{
    const [scrollupButton, setScrollUpButton] = useState(false)
    const [location, setLocation] = useState('')
    const [nav, setNav] = useState(false);
    const changeBackground = () => {

        if (window.pageYOffset > 90) {
            setLocation('')
        }
        if (window.pageYOffset > 400) {

            setLocation('features')
        }
        if (window.pageYOffset > 1200) {

            setLocation('partner')
        }
        if (window.pageYOffset > 4000) {

            setLocation('aboutus')
        }
        if (window.scrollY >= 50) {
            setScrollUpButton(true)
            setNav(true);
        } else {
            setScrollUpButton(false)
            setNav(false);
        }
        if (window.scrollY === 50) {

            setLocation('')
        }
        if (window.scrollY === 80) {


        }
    }
    function scrollup() {
        window.scrollTo(0, 0)
    }
    window.addEventListener('scroll', changeBackground)
    return (
        <div className='App'>
            <div id='main'>
            <nav className={nav?'nav active':'nav md:px-0'}>
                    <a href="/#" className='logo'>
                        <img src={Brandlogo} alt="" className='md:w-150' />
                    </a>
                    <input type="checkbox" className='menu-btn' id='menu-btn' />
                    <label className='menu-icon' htmlFor="menu-btn" >
                        <span className='nav-icon'></span>
                    </label>
                    <ul className='menu'>
                        <li>
                        <a className={location === '' ? 'active' : ''} href='#main' onClick={() => setLocation('')} >Home</a>
                        </li>
                        <li>
                            <a className={location === 'features' ? 'active' : ''} href="#features" onClick={() => {setLocation('features')}} >Our Service</a>
                            </li>
                        <li>
                            <a className={location === 'partner' ? 'active' : ''}  onClick={() => {setLocation('partner')}} href="#partner" >Become a Partner</a>
                            </li>
                        <li><a className={location === 'aboutus' ? 'active' : ''} onClick={() => {setLocation('aboutus')}} href="#aboutus">About</a></li>
                    </ul>
                </nav>
                <div className='justify-center m-auto lg:flex' style={{ 'height': 'inherit' }}>

                    <div id='home' className='lg:w-3/4 lg:m-auto lg:px-28 name md:w-full md:text-sm'>
                        <div className='lg:text-5xl lg:mb-4'>
                            <span>Revolutionising Home Healthcare</span>
                        </div>
                        <p className='details lg:text-lg'>Medverse is a digital first-home healthcare company, that enables healthcare providers and patients to power care services into the home, fully integrated care management platform that bridges the virtual care gap with physician-led telemedicine combined with its network of medical professionals</p>
                        <a href="#features" className='mt-3 cv-btn'> Discover More</a>
                    </div>
                    <div className='w-1/2 m-auto md:w-2/2'>
                        <img src={Doctor} alt="doctor" className='m-auto ' width={'70%'} />
                    </div>

                </div>

            </div>
            <Features id='features' />
            
            <Works  />
            
            <div id='partner'>
            <Partner />
            </div>
          <div id='contact'>
          <Contact  />
          </div>
            
            
              <div id='aboutus'>
        <FeatureBox id='aboutus' image={AboutImages1} title="Download all the app now  " button="Download" />
        </div>
            <section className="bg-violet-800">
                                <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
                    <nav className="flex flex-wrap justify-center -mx-5 -my-2">
                        <div className="px-5 py-2">
                            <a href='#main' onClick={() => setLocation('')} className="text-base leading-6 text-white duration-700 hover:border-b hover:border-white hover:pb-2 hover:transition-all hover:scale-110 ">
                                Home
                            </a>
                        </div>
                        <div className="px-5 py-2">
                            <a onClick={() => setLocation('aboutus')} href="#aboutus" className="text-base leading-6 text-white duration-700 hover:border-b hover:border-white hover:pb-2 hover:transition-all hover:scale-110 ">
                                About
                            </a>
                        </div>
                        <div className="px-5 py-2">
                            <a href="#contact" className="text-base leading-6 text-white duration-700 hover:border-b hover:border-white hover:pb-2 hover:transition-all hover:scale-110 ">
                                Contact Us
                            </a>
                        </div>
                        <div className="px-5 py-2">
                            <a href="#partner" className="text-base leading-6 text-white duration-700 hover:border-b hover:border-white hover:pb-2 hover:transition-all hover:scale-110 ">
                                Become a Partner
                            </a>
                        </div>
                        <div className="px-5 py-2">
                            <a href="#features" onClick={() => setLocation('features')} className="text-base leading-6 text-white duration-700 hover:border-b hover:border-white hover:pb-2 hover:transition-all hover:scale-110 ">
                                Service
                            </a>
                        </div>
                    </nav>
                    <div className="flex justify-center mt-8 space-x-6">
                        <a href="https://www.linkedin.com/company/medversecare/" className="text-gray-400 hover:text-gray-500" target='_blank'>
                            <span className="sr-only">Facebook</span>
                            <BsLinkedin className='text-xl text-pink-600 hover:text-white' />
                        </a>

                        <a href="#" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Twitter</span>
                            <BsTwitter className='text-xl text-pink-600 hover:text-white' />
                        </a>
                        <a href="#" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Instagram</span>
                            <BsYoutube className='text-xl text-pink-600 hover:text-white' />
                        </a>

                    </div>
                    <p className="mt-8 text-base leading-6 text-center text-white">
                        <div>Copyright © 2023 Medverse@ATHRU TECHNOLOGIES. All rights reserved.</div>
                        Head-Office: Hebbal, Bengaluru
                    </p>
                </div>
            </section>
            {scrollupButton ? <GoMoveToTop className='fixed p-4 text-5xl text-pink-700 bg-white rounded-full shadow-lg cursor-pointer lg:w-16 lg:h-16 bottom-10 right-9 hover:bg-pink-700 hover:text-white' onClick={() => scrollup()} /> : ''
            }
        </div>
    );
}


export default Landing;