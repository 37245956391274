import { React, useState } from 'react';

import user3 from './assets/Shivanand.jpeg';
import user2 from './assets/Gopal_2.jpg';
import user1 from './assets/Avinash.jpg';
import user4 from './assets/Arun Tumati.jpg';
import user5 from './assets/Ravi Katageri.jpg';
import user6 from './assets/Dr Paramesh.jpg';
import user7 from './assets/Dr Divakar.jpg';
import user8 from './assets/Dr K S Gopinath.png';
const FeatureBox = (props) => {

    const ListArray = [
        {
            imagesUrl: user1,
            userName: 'Avinash Gosi',
            role: 'DEVELOPER',
            discription: 'Avinash has immersed himself in the healthcare technology domain for over 16 years, leading the charge in transforming healthcare through ground breaking and innovative technological solutions. He holds Masters in Computer Application from Bangalore University, and an avid Badminton player. Avinash joined Gopal to solve the problems in healthcare services industry through technology. His dedication and genuine passion have kept him at the forefront of this transformative journey.',
            cardType: '1',
            size: false
        },
        {
            imagesUrl: user2,
            userName: 'Gopal Mukund',
            role: 'CEO',
            discription: 'Gopal has extensive knowledge and experience over 17 years in diverse businesses, including healthcare, proprietary trading in derivatives market and successfully running a distribution enterprise that supplies FMCG and OTC products, Retail & Organic cattle farming.',
            cardType: "1",
            size: false
        },
        {
            imagesUrl: user3,
            userName: 'Shivanand',
            role: 'COO',
            discription: 'Shivanand brings over 15 years of diverse IT experience in critical domains such as Banking, Insurance, Retail, Supply Chain, Utilities, and Asset Management in both Indian and UK markets. His exceptional expertise, passion for data, and analytical acumen make him a valuable asset in data-driven decision-making. His proficiency in building Azure AI models empowers us to drive automation and intelligence in our business operations, ensuring continued success at Medverse.',
            cardType: "1",
            size: false
        },
        {
            imagesUrl: user4,
            userName: 'Arun Tumati',
            role: 'TECHNOLOGY ADVISOR',
            discription: 'Arun Tumati, Director and COO of Starmark Services Pvt Ltd, has over 30 years of experience in IT, specializing in healthcare product development and management.',
            cardType: '2',
            size: false
        },
        {
            imagesUrl: user5,
            userName: 'Ravi Katageri',
            role: 'TECHNOLOGY ADVISOR',
            discription: 'With over 30 years of experience in IT, including techno-commercial and HR expertise, the CTO of Linkzie Technologies Pvt. Ltd. is determined to build profitable business units within the organizations.',
            cardType: '2',
            size: false
        },
        {
            imagesUrl: user6,
            userName: 'Dr Paramesh Somanna',
            role: 'MEDICAL ADVISOR',
            discription: 'Dr. Paramesh Shamanna is a leading diabetologist with over 30 years of expertise in Diabetology and Internal Medicine. He practices in the Bangalore Diabetes Centre in Bengaluru. After completing his MBBS from Bangalore Medical College and Research Institute in India, Dr Paramesh earned his MD in General Medicine from Karnataka Institute of Medical Sciences, Hubli( KIMS). He also did his Post Graduate Diploma in Diabetology(PGDD) from Annamalai University and specializes in Diabetes and Internal Medicine. He is also a Managing Director at Twin Health, with a demonstrated history of working in the health wellness and fitness industry. Strong business development professional skilled in Diabetes, Electronic Data Capture (EDC), Clinical Research, CRO Management, and Medicine.',
            cardType: '2',
            size: false
        },
        {
            imagesUrl: user7,
            userName: 'Dr Divakar Bhat',
            role: 'MEDICAL ADVISOR',
            discription: "Dr. Divakar Bhat is among the nation's renowned Cardiovascular and Vascular surgeons.Possessing rich experience in the field. Dr. Divakar Bhat earned his degree of M.Ch (CTVS) in the year 2001 from the Postgraduate Institute of Medical Education, Chandigarh, India. He also secured an M.S (General Surgery) degree from Government Medical College, Mysore, India between the years 1993 and 1996. He did his MBBS from the Karnataka Medical College, Hubli, India. He also pursued his fellowship in CTVS and Transplant Surgery for two years at St Vincent Hospital, Sydney.",
            cardType: '2',
            size: false
        },
        {
            imagesUrl: user8,
            userName: 'Dr K S Gopinath',
            role: 'MEDICAL ADVISOR',
            discription: "Dr. Kodaganur S. Gopinath, MS, FAMS, FRCS (Edin) is a surgical oncologist, known for his pioneering work ononcological research. He is a recipient of many awards including Dr. B. C. Roy Award, considered to be the premier medical honour in the country. The President of India recognised his services to the field of oncology, by awarding him the fourth highest civilian award, Padma Shri, in 2010.",
            cardType: '2',
            size: false
        },
    ]
    const [teamList, setTeamList] = useState(ListArray)

    // const [size, setSize] = useState(false)
    const expand = (item,value) => {
        let findIndexUser = teamList.findIndex(va => va.userName === item.userName);
        teamList.forEach((team) => {
            team.size = false;
        })
        
            teamList[findIndexUser].size =value ;
        setTeamList( // Replace the state
        [ // with a new array
          ...teamList, // that contains all the old items
           // and one new item at the end
        ]
      );
      
    }
    const setStyle = (item) => {
        if (item.cardType === '1' && !item.size) {
            return 'w-72 overflow-hidden overflow-x-hidden font-thin card_content'
        }
        if (item.cardType === '1' && item.size) {
            return 'w-auto overflow-hidden overflow-x-hidden font-thin card_content'
        }
        if (item.cardType === '2' && !item.size) {
            return 'lg:w-52 w-full overflow-hidden overflow-x-hidden font-thin card_content'
        }
        if (item.cardType === '2' && item.size) {
            return 'w-auto overflow-hidden overflow-x-hidden font-thin card_content'
        }
    }
    const setExpandStyle = (item) => {
        if (item.cardType === '1' && !item.size) {
            return 'rounded-3xl p-6  text-white   bg-white m-2 shadow-lg transition-all duration-700'
        }
        if (item.cardType === '1' && item.size) {
            return 'rounded-3xl p-6 w-[500px] text-white    bg-white m-2 shadow-lg transition-all duration-700 scale-110';
        }
        if (item.cardType === '2' && !item.size) {
            return 'rounded-3xl p-6  text-white   w-full  bg-white m-2 shadow-lg transition-all duration-700'
        }
        if (item.cardType === '2' && item.size) {
            return 'rounded-3xl p-6 w-[300px] text-white  w-full   bg-white m-2 shadow-lg transition-all duration-700 scale-110';
        }
    }
    return (
        <>
            <div className='bg-[#f09cd947] py-10'  >
                <div className="mb-8 text-2xl"> Meet the MedVerse Team</div>
                <div className='mx-10 mb-5 a-container'>
                    {

                        teamList.map((va,index) => {
                            return (

                                va.cardType === '1' ?
                                    <div key={index}>

                                        <div className='flex place-items-center'>
                                            <div className={setExpandStyle(va)} >
                                                <div className='flex item-center'>
                                                    <img src={va.imagesUrl} className={`w-20 h-20 rounded-full` + (va.cardType === '2' ? " mx-auto w-20 h-20 rounded-full" : " ")} style={{ 'objectFit': 'cover' }} alt='iamges_section' />
                                                    {va.cardType === '1' ?
                                                        <div className='pt-3 ms-5'>
                                                            <div className='grid text-black'>
                                                                <span className='text-lg font-semibold text-left'>{va.userName}</span>
                                                                <span className='text-sm font-thin text-gray-400'>{va.role}</span>
                                                            </div>

                                                        </div> : ''
                                                    }

                                                </div>


                                                <div className='pt-5'>
                                                    <div className='grid text-left text-black'>
                                                        <span className='font-semibold text-pink-600'>About</span>
                                                        <div className={setStyle(va)} style={{ height: va.size ? 'auto' : '90px' }}>
                                                            {va.discription}
                                                        </div>
                                                        <div className='flex justify-end mt-5 text-pink-600 cursor-pointer -right-4 ' onClick={() => expand(va,!va.size)}>{va.size ? 'Less' : " More.."}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''

                            )
                        })
                    }
                </div>
            </div>


            <div className='bg-[#f09cd947] py-10' >
                <div className="mb-8 text-2xl"> Our Advisors</div>
                <div className='mx-5 mb-5 lg:mx-10 a-container'>
                    {
                        teamList.map((vas,index) => {

                            return (
                                vas.cardType === '2' ? <div  className='w-[100%]' key={index}>
                                    <div >

                                        <div className='flex place-items-center'>
                                            <div className={setExpandStyle(vas)} >
                                                <div className='flex item-center'>
                                                    <img src={vas.imagesUrl} className={`w-20 h-20 rounded-full` + (vas.cardType === '2' ? " mx-auto w-20 h-20 rounded-full" : " ")} style={{ 'objectFit': 'cover' }} alt='iamges_section' />
                                                </div>

                                                <div className='pt-3 ms-5'>
                                                    <div className='grid text-black'>
                                                        <span className='text-sm font-semibold '>{vas.userName}</span>
                                                        <span className='text-sm font-thin text-gray-400'>{vas.role}</span>
                                                    </div>

                                                </div>

                                                <div className='pt-5'>
                                                    <div className='grid text-left text-black'>
                                                        <span className='font-semibold text-pink-600'>About</span>
                                                        <div className={setStyle(vas)} style={{ height: vas.size ? 'auto' : '90px' }}>
                                                            {vas.discription}
                                                        </div>
                                                        <div className='flex justify-end mt-5 text-pink-600 cursor-pointer -right-4 ' onClick={() => expand(vas,!vas.size)}>{vas.size ? 'Less' : " More.."}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default FeatureBox;